











































import Vue from 'vue'
import AppBarLanguageSwitcher from '@/components/AppBarLanguageSwitcher.vue'
import store from '@/store'

type Data = {
  desktopShowFullSidebar: boolean
  drawerIsOpen: boolean | null
  loggedIn: boolean
}

export default Vue.extend({
  name: 'GuestLayout',
  components: { AppBarLanguageSwitcher },
  data: (): Data => {
    return {
      desktopShowFullSidebar: true,
      drawerIsOpen: null, // initial null -> open in desktop, closed in mobile
      loggedIn: store.getters.loggedIn,
    }
  },
  computed: {
    showFullSidebar() {
      const isMobile = this.$vuetify.breakpoint.mobile

      return isMobile ? true : this.desktopShowFullSidebar
    },
  },
  methods: {
    toggleShowDesktopFullSidebar() {
      this.desktopShowFullSidebar = !this.desktopShowFullSidebar
    },
    toggleDrawer() {
      this.drawerIsOpen = !this.drawerIsOpen
    },
  },
})
