







































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { mdiAccountOutline, mdiLogoutVariant } from '@mdi/js'

export default Vue.extend({
  name: 'AppBarUserMenu',
  data: function () {
    return {
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods: {
    ...mapActions(['logout']),
    logOut: function () {
      this.logout()
    },
    gotoUserProfile: function () {
      //todo redirect to profile view
    },
  },
  computed: {
    ...mapGetters(['fullName', 'role', 'email']),
  },
})
