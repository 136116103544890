import type { GetterTree } from 'vuex'
import type { IRootState } from '@/store/types'
import type { IUserState } from '@/store/me/types'

export const getters: GetterTree<IUserState, IRootState> = {
  me(state: IUserState): IUserState | null {
    return state
  },

  fullName({ name }: IUserState): string | null {
    return name
  },

  role({ role }: IUserState): string | null {
    return role
  },

  email({ email }: IUserState): string | null {
    return email
  },
}
