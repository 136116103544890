import Vue from 'vue'
import store from '@/store'
import VueRouter, { RouteConfig } from 'vue-router'
import { UserRoles } from '@/enums/UserRoles'

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'PublicPublishedSellingTenders',
    component: () =>
      import(
        /* webpackChunkName: "published-selling-tender-list" */ '@/views/PublishedTender/PublishedSellingTendersList.vue'
      ),
    meta: {
      layout: 'GuestLayout',
    },
  },
  {
    path: '/buying-tenders/:id/make-offer',
    name: 'MakeBuyingOffer',
    component: () => import(/* webpackChunkName: "make-buying-offer" */ '@/views/PublishedTender/MakeBuyingOffer.vue'),
    meta: { allowedRoles: [UserRoles.client], requiresAuth: true, layout: 'LoggedInLayout' },
  },
  {
    path: '/selling-tenders/:id/make-offer',
    name: 'MakeSellingOffer',
    component: () =>
      import(/* webpackChunkName: "make-selling-offer" */ '@/views/PublishedTender/MakeSellingOffer.vue'),
    meta: { allowedRoles: [UserRoles.client], requiresAuth: true, layout: 'LoggedInLayout' },
  },
  {
    path: '/buying-tenders/:id/make-offer-on-behalf',
    name: 'MakeBuyingOfferOnBehalf',
    component: () =>
      import(/* webpackChunkName: "make-buying-offer" */ '@/views/PublishedTender/MakeBuyingOfferOnBehalf.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/selling-tenders/:id/make-offer-on-behalf',
    name: 'MakeSellingOfferOnBehalf',
    component: () =>
      import(/* webpackChunkName: "make-buying-offer" */ '@/views/PublishedTender/MakeSellingOfferOnBehalf.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/activate',
    name: 'ActivateUser',
    component: () => import(/* webpackChunkName: "activate" */ '@/views/Auth/ActivateUser.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/Auth/ForgotPassword.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/Auth/ResetPassword.vue'),
    meta: { requiresAuth: true, layout: 'AuthLayout' },
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: () => import(/* webpackChunkName: "create-password" */ '@/views/Auth/CreatePassword.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/two-factor-auth',
    name: 'TwoFactorAuth',
    component: () => import(/* webpackChunkName: "two-factor-auth" */ '@/views/Auth/TwoFactorAuth.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/enable-2fa',
    name: 'Enable2FA',
    component: () => import(/* webpackChunkName: "enable2-fa" */ '@/views/Auth/Enable2FA.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Dashboard/Home.vue'),
    meta: { requiresAuth: true, layout: 'LoggedInLayout', drawer: { label: 'home', icon: 'mdi-home-outline' } },
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients/ClientList.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'clients', icon: 'mdi-briefcase-outline' },
    },
  },
  {
    path: '/client/show/:id',
    name: 'ShowClient',
    component: () => import(/* webpackChunkName: "show-client" */ '@/views/Clients/ShowClient.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/tenders',
    name: 'Tenders',
    component: () => import(/* webpackChunkName: "tenders" */ '@/views/Tender/Tenders.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: {
        label: 'tenders',
        icon: 'mdi-text-box-multiple-outline',
      },
    },
    children: [
      {
        path: '/buying-tenders',
        name: 'BuyingTenders',
        component: () => import(/* webpackChunkName: "buying-tenders" */ '@/views/Tender/BuyingTenders.vue'),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'buyingTenders',
        },
      },
      {
        path: '/selling-tenders',
        name: 'SellingTenders',
        component: () => import(/* webpackChunkName: "selling-tenders" */ '@/views/Tender/SellingTenders.vue'),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'sellingTenders',
        },
      },
    ],
  },
  {
    path: '/published-tenders',
    name: 'PublishedTenders',
    component: () => import(/* webpackChunkName: "published-tenders" */ '@/views/PublishedTender/PublishedTenders.vue'),
    meta: {
      allowedRoles: [UserRoles.client, UserRoles.commissioner, UserRoles.operationOperator],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: {
        label: 'publishedTenders.label',
        icon: 'mdi-text-box-multiple-outline',
      },
    },
    children: [
      {
        path: '/published-tenders/buying',
        name: 'PublishedBuyingTenders',
        component: () =>
          import(
            /* webpackChunkName: "published-buying-tenders" */ '@/views/PublishedTender/PublishedBuyingTendersList.vue'
          ),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'buyingTenders',
        },
      },
      {
        path: '/published-tenders/selling',
        name: 'PublishedSellingTenders',
        component: () =>
          import(
            /* webpackChunkName: "published-selling-tenders" */ '@/views/PublishedTender/PublishedSellingTendersList.vue'
          ),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'sellingTenders',
        },
      },
    ],
  },
  {
    path: '/closed-tender-details/:id',
    name: 'ClosedTenderDetails',
    component: () => import(/* webpackChunkName: "closed-tender-details" */ '@/views/Tender/views/TenderDetails.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationOperator, UserRoles.operationDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/evaluate-buying-tender/:id',
    name: 'EvaluateBuyingTender',
    component: () =>
      import(/* webpackChunkName: "evaluate-buying-tender" */ '@/views/Tender/Evaluation/EvaluateBuyingTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },

  {
    path: '/evaluate-selling-tender/:id',
    name: 'EvaluateSellingTender',
    component: () =>
      import(/* webpackChunkName: "evaluate-selling-tender" */ '@/views/Tender/Evaluation/EvaluateSellingTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresVisitor) && store.getters.loggedIn) {
    next({ name: 'Home' })
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.loggedIn) {
    next({ name: 'Login' })
  } else {
    const notAllowed = to.matched.some((record) => {
      return record.meta.allowedRoles && !record.meta.allowedRoles.includes(store.getters.role)
    })
    if (notAllowed) next({ name: 'Home' })
    next()
  }
})
